var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content-right"
  }, [_c('div', {
    staticClass: "user-top"
  }, [_c('div', {
    staticClass: "user-head"
  }, [_vm._m(0), _c('div', {
    staticClass: "portrait-describe"
  }, [_c('p', {
    staticClass: "describe-title"
  }, [_vm._v(_vm._s(_vm.loginCompanyName))]), _c('p', {
    staticClass: "describe-time"
  }, [_vm._v("上次登陆："), _c('span', [_vm._v(_vm._s(_vm.lastLoginTime))])])])]), _vm._m(1)]), !_vm.isExternal ? _c('div', {
    staticClass: "user-order"
  }, [_c('div', {
    staticClass: "order-title"
  }, [_vm._v("订单")]), _c('div', {
    staticClass: "order-caption"
  }, [_c('dl', {
    on: {
      "click": _vm.handleUnpaid
    }
  }, [_vm._m(2), _c('dd', [_vm._v("待付款")])]), _c('dl', {
    on: {
      "click": _vm.handleGoods
    }
  }, [_vm._m(3), _c('dd', [_vm._v("待发货")])]), _c('dl', {
    on: {
      "click": _vm.handleTake
    }
  }, [_vm._m(4), _c('dd', [_vm._v("待收货")])]), _c('dl', {
    on: {
      "click": _vm.handleaCcomplish
    }
  }, [_vm._m(5), _c('dd', [_vm._v("交易完成")])]), _c('dl', {
    on: {
      "click": _vm.handleCancel
    }
  }, [_vm._m(6), _c('dd', [_vm._v("已取消")])])])]) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "portrait"
  }, [_c('img', {
    attrs: {
      "src": require("./images/user.png"),
      "alt": ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "transfer-box"
  }, [_c('h2', [_vm._v("对公转账信息")]), _c('div', {
    staticClass: "transfer-box-content"
  }, [_c('div', {
    staticClass: "content-item"
  }, [_c('p', [_vm._v("单位名称:")]), _c('h3', [_vm._v("重庆普健医药有限公司")])]), _c('div', {
    staticClass: "content-item"
  }, [_c('p', [_vm._v("银行账号:")]), _c('h3', [_vm._v("1239 0525 9210 302")])]), _c('div', {
    staticClass: "content-item"
  }, [_c('p', [_vm._v("开户银行:")]), _c('h3', [_vm._v("招商银行重庆总部城支行")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('dt', [_c('img', {
    attrs: {
      "src": require("./images/icon_ payment.png"),
      "alt": ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('dt', [_c('img', {
    attrs: {
      "src": require("./images/icon_ goods.png"),
      "alt": ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('dt', [_c('img', {
    attrs: {
      "src": require("./images/icon_take.png"),
      "alt": ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('dt', [_c('img', {
    attrs: {
      "src": require("./images/icon_ complete.png"),
      "alt": ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('dt', [_c('img', {
    attrs: {
      "src": require("./images/icon_cancel.png"),
      "alt": ""
    }
  })]);
}];
export { render, staticRenderFns };