import "core-js/modules/es.array.push.js";
// import { ProductList } from "@components/product-list";
// import { productListData } from "./center-data.js";
import { AccountStorage } from "@services/account";
var accountStorage = new AccountStorage();
export default {
  components: {
    // ProductList,
  },
  data: function data() {
    return {
      showRightList: true,
      isLogin: false,
      // productListData,
      list: [],
      companyId: "",
      // 企业id
      loginCompanyName: "",
      gender: "",
      lastLoginTime: "",
      isExternal: false
    };
  },
  created: function created() {
    var loginCompanyId = accountStorage.getCacheUserInfo();
    this.companyId = loginCompanyId.loginCompanyId;
    this.gender = accountStorage.getCacheUserInfo().gender;
    this.isExternal = accountStorage.getCacheUserInfo().type == 1 ? true : false;
    this.loginCompanyName = this.isExternal ? accountStorage.getCacheUserInfo().userName : accountStorage.getCacheUserInfo().loginCompanyName;
    this.lastLoginTime = accountStorage.getCacheUserInfo().lastLoginTime;
    this.getPageList();
  },
  methods: {
    onSearch: function onSearch() {},
    handleDefault: function handleDefault(props) {
      this.index = props.row.index;
    },
    // 积分显示
    getPageList: function getPageList() {
      var _this = this;
      var data = {
        companyId: this.companyId
      };
      var url = "/pjyy-deepexi-user-center/api/v1/point/pageList";
      this.$getHttpClient().post(url, data).then(function (res) {
        _this.list = res.data.data.list;
      });
    },
    toLink: function toLink() {
      this.$router.push({
        path: "/user-center/integral",
        query: {
          ind: "jx"
        }
      });
    },
    // // 跳转待支付
    handleUnpaid: function handleUnpaid() {
      this.$router.push({
        name: "order",
        params: {
          status: "01"
        }
      });
    },
    // 跳转待发货
    handleGoods: function handleGoods() {
      this.$router.push({
        name: "order",
        params: {
          status: "02"
        }
      });
    },
    // 跳转待收货
    handleTake: function handleTake() {
      this.$router.push({
        name: "order",
        params: {
          status: "03"
        }
      });
    },
    // 跳转交易完成
    handleaCcomplish: function handleaCcomplish() {
      this.$router.push({
        name: "order",
        params: {
          status: "04"
        }
      });
    },
    // 跳转取消
    handleCancel: function handleCancel() {
      this.$router.push({
        name: "order",
        params: {
          status: "05"
        }
      });
    },
    // 跳转取消
    handleSending: function handleSending() {
      this.$router.push({
        name: "order",
        params: {
          status: "07"
        }
      });
    },
    //跳转去认证
    toAudit: function toAudit() {
      var _this2 = this;
      var url = '/pjyy-deepexi-user-center/api/v1/customer/myAuditStatus';
      this.$getHttpClient().get(url).then(function (res) {
        if (res.data.code == '200' && res.data.data.auditStatus == 'NOTSUB') {
          _this2.$router.push({
            path: '/user-center/submit-qualification'
          });
        } else {
          _this2.$router.push({
            path: '/user-center/audit-detail'
          });
        }
      });
    }
  },
  updated: function updated() {},
  mounted: function mounted() {}
};